import React from "react"

import styles from "./Iframe.module.scss"

export interface IframeProps {
	src?: string
	alt?: string
}

const Iframe: React.FC<IframeProps> = ({src, alt}) => {
	const containerRef = React.useRef<HTMLDivElement | null>(null)

	React.useEffect(() => {
		if (containerRef.current && src && alt) {
			const iframe = document.createElement("iframe")
			iframe.src = src
			iframe.title = alt
			iframe.className = styles.iframe
			iframe.frameBorder = "0"
			iframe.allowFullscreen = true
			containerRef.current.appendChild(iframe)
		}
	}, [src, alt])

	return <div ref={containerRef} className={styles.iframeContainer} />
}

export default Iframe

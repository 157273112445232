import Popup from "@components/BasicComponents/Popup/Popup"
import Asset from "@components/CustomTypography/Asset/Asset"
import Content from "@components/CustomTypography/Content/Content"
import Separator from "@components/CustomTypography/Separator/Separator"
import {
	AdvancedSideAssetInsideContent,
	AdvancedSideAssetMainContent,
} from "@hooks/WorkWithJson/GetComponentDataByKey/GetComponentDataByKeyTypes"
import React from "react"

import styles from "./PopupSwitcher.module.scss"

export type PopupSwitcherType = {
	handleClick?: () => void
	data: AdvancedSideAssetMainContent & AdvancedSideAssetInsideContent
	type?: "content" | "asset" | "default"
}

const PopupSwitcher = (props: PopupSwitcherType) => {
	const asset = props.data.insideContent?.inside
		? props.data.insideContent?.inside
		: undefined

	const content =
		props.data.insideContent?.title || props.data.insideContent?.content
			? props.data.insideContent
			: props.data

	const contentStyle =
		props.type !== "content" && asset?.type !== "none" && asset
			? styles.withAsset
			: styles.withoutAsset

	const containerClassName =
		props.type === "asset" && asset ? styles.containerClassName : ""

	return (
		<Popup
			containerClassName={containerClassName}
			handleClick={props.handleClick}
			module={
				<>
					<Separator className={styles.container} withoutMeasure>
						{props.type !== "content" &&
							asset?.type !== "none" &&
							asset && (
								<div className={styles.assetBox}>
									<Asset
										data={{
											...asset,
										}}
										type={asset?.type || "string"}
									/>
								</div>
							)}
						{props.type !== "asset" && (
							<div
								className={`${styles.contentBox} ${contentStyle}`}
							>
								<Content
									data={content}
									size={"L"}
									subtitleProps={{
										fontWeight: 400,
										size: "L",
										content: content.title,
										withoutHtml: true,
									}}
									titleProps={{
										content: content.title,
										fontWeight: 600,
										size: "L",
										center:
											props.type === "content" ||
											asset?.type === "none",
									}}
								/>
							</div>
						)}
					</Separator>
				</>
			}
		/>
	)
}

export default PopupSwitcher

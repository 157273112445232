import styles from "./Overlay.module.scss"

type Props = {
	opacity?: string
}

const Overlay = (props: Props) => {
	return (
		<div
			className={styles.overlay}
			style={{opacity: props.opacity ? props.opacity : "0.5"}}
		/>
	)
}

export default Overlay

import ContainerWrapper from "@components/BasicComponents/Wrappers/ContainerWrapper/ContainerWrapper"
import ButtonComponent from "@components/Theme/Button/Button"
import {useEffect, useRef} from "react"

import ReactPortal from "../../ReactPortal"

import styles from "./Popup.module.scss"
import {PopupType} from "./PopupType"

const Popup = (props: PopupType): any => {
	useEffect(() => {
		return () => {
			overflowChange()
		}
	}, [])

	// Turning off/on main scroll bar, depended on open/close property
	document.body.style.overflowY = "hidden"
	const overflowChange = () => {
		document.body.style.overflowY = "scroll"
	}

	const newRef = useRef<HTMLDivElement>(null)
	const handleOutsideClick = (e: MouseEvent) => {
		if (
			newRef.current &&
			!newRef?.current?.contains(e.target as Node) &&
			props.handleClick
		) {
			props.handleClick()
		}
	}

	useEffect(() => {
		document.addEventListener("mousedown", handleOutsideClick)
		return () => {
			document.removeEventListener("mousedown", handleOutsideClick)
		}
	})

	return (
		<ReactPortal wrapperId={"portal"}>
			<div className={`${styles.container}`}>
				<ContainerWrapper className={`${styles.container__wrapper}`}>
					<div
						ref={newRef}
						className={`${styles.content__container} `}
					>
						<div
							className={`${styles.content__wrapper} ${props?.containerClassName}`}
						>
							{/* Inserting Custom Popup Module */}
							{props.module}
						</div>
						{/* Close Button */}
						<div className={styles.exit__button}>
							<ButtonComponent
								buttonType={"exit"}
								callback={overflowChange}
								size={"large"}
								onClick={props.handleClick}
							/>
						</div>
					</div>
				</ContainerWrapper>
			</div>
		</ReactPortal>
	)
}

export default Popup

import {
	AssetContentType,
	AssetType,
} from "@hooks/WorkWithJson/GetComponentDataByKey/GetComponentDataByKeyTypes"
import React from "react"

import Iframe, {IframeProps} from "../Iframe/Iframe"
import NewResponsiveImage, {
	ResponsiveImageProps,
} from "../NewResponsiveImage/NewResponsiveImage"
import Paragraph, {ParagraphType} from "../Paragraph/Paragraph"

type Props = {
	data: ResponsiveImageProps | IframeProps | ParagraphType
	type: AssetContentType
}

const Asset = (props: Props) => {
	if (props.type === "image") {
		return <NewResponsiveImage {...checkTypeImage(props.data)} />
	}
	if (props.type === "video") {
		return <Iframe {...checkTypeIframe(props.data)} />
	}
	if (props.type === "string") {
		return <Paragraph {...checkTypeParagrapg(props.data)} />
	}
	return <></>
}

const checkTypeImage = (data: any) => {
	return data as AssetType
}
const checkTypeIframe = (data: any) => {
	return data as IframeProps
}
const checkTypeParagrapg = (data: any) => {
	return data as ParagraphType
}

export default Asset

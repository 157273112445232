import Link from "@components/Theme/Link/Link"
import {contentSize, titleType} from "@customTypes/customTypes"
import {AdvancedSideAssetMainContent} from "@hooks/WorkWithJson/GetComponentDataByKey/GetComponentDataByKeyTypes"
import React from "react"

import Description, {DescriptionType} from "../Description/Description"
import Paragraph, {ParagraphType} from "../Paragraph/Paragraph"
import Title, {TitleComponentType} from "../Title/Title"

export type ContentType = {
	size?: contentSize
	data?: AdvancedSideAssetMainContent
	titleType?: titleType
	shortDesc?: number
	without?: {
		upperTitle?: boolean
		title?: boolean
		subtitle?: boolean
		description?: boolean
	}
	onlyUpperTitle?: boolean
	onlyTitle?: boolean
	onlySubtitle?: boolean
	onlyDescription?: boolean
	asLink?: string
	asImagePopup?: boolean
	titleProps?: TitleComponentType
	subtitleProps?: ParagraphType
	upperTitleProps?: ParagraphType
	contentProps?: DescriptionType
	maxWidth?: string
}

const Content = (props: ContentType) => {
	const content = (
		<div
			style={{
				maxWidth: props.maxWidth || "100%",
				margin: props.maxWidth ? "0 auto" : "0",
			}}
		>
			{!props.without?.upperTitle && (
				<Paragraph
					{...props.upperTitleProps}
					content={props.data?.upperTitle}
					size={props.size}
				/>
			)}
			{!props.without?.title && (
				<Title
					{...props.titleProps}
					content={props.data?.title}
					size={props.size}
					type={props.titleType}
				/>
			)}
			{!props.without?.subtitle && (
				<Paragraph
					{...props.subtitleProps}
					content={props.data?.subtitle}
					size={props.size}
				/>
			)}
			{!props.without?.description && (
				<Description
					{...props.contentProps}
					content={props.data?.content}
					shortDesc={props.shortDesc}
					size={props.size}
				/>
			)}
		</div>
	)

	return (
		<>
			{props.asLink && <Link link={props.asLink ?? "/#"}>{content}</Link>}
			{props.asImagePopup && (
				<Link link={props.asLink ?? "/#"}>{content}</Link>
			)}
			{!props.asLink && <>{content}</>}
		</>
	)
}

export default Content

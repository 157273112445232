import ButtonComponent from "@components/Theme/Button/Button"
import ButtonComponentType from "@components/Theme/Button/ButtonComponent"
import LinkComponent from "@components/Theme/Link/LinkComponent"
import {
	AdvancedSideAssetInsideContent,
	AdvancedSideAssetMainContent,
	BackendButton,
} from "@hooks/WorkWithJson/GetComponentDataByKey/GetComponentDataByKeyTypes"
import React, {useState} from "react"

import PopupSwitcher from "../PopupSwitcher/PopupSwitcher"

type Props = ButtonComponentType &
	LinkComponent & {
		asPost?: boolean
		link?: string
		popup?: {
			data: AdvancedSideAssetMainContent | AdvancedSideAssetInsideContent
			type: "content" | "asset" | "default"
		}
		buttonsList?: AdvancedSideAssetMainContent
		buttonContent?: any
	}

const ButtonSwitcher = (props: Props) => {
	// Popup functionality
	const [popup, setPopup] = useState<boolean>(false)
	const changePopup = (): void => {
		setPopup(!popup)
	}

	let buttonType
	if (
		typeof props.buttonsList !== "undefined" &&
		typeof props.buttonsList?.buttons !== "undefined" &&
		props.buttonsList.buttons.length > 0
	) {
		buttonType = (
			<>
				{props.buttonsList.buttons.map(
					(element: BackendButton, index: number) => {
						return (
							<React.Fragment key={index}>
								<ButtonComponent
									key={index}
									asLink={element.linkPopup}
									withoutTarget={props.withoutTarget}
									{...element}
									onClick={
										!element.linkPopup
											? changePopup
											: () => {}
									}
								>
									{element.text}
								</ButtonComponent>
							</React.Fragment>
						)
					},
				)}
				{popup && (
					<PopupSwitcher
						data={
							typeof props.buttonsList !== "undefined"
								? props.buttonsList
								: {}
						}
						handleClick={changePopup}
						type={props.popup?.type ? props.popup.type : "default"}
					/>
				)}
			</>
		)
	} else if (typeof props.buttonsList === "undefined") {
		buttonType = (
			<>
				<ButtonComponent
					asLink={
						props.asLink || props.link !== undefined || props.asPost
					}
					buttonType={
						props.buttonType ? props.buttonType : "readMore"
					}
					link={props.link}
					size={props.size ? props.size : "default"}
					withoutTarget={props.withoutTarget}
					onClick={
						!props.asLink &&
						props.link === undefined &&
						!props.asPost
							? changePopup
							: () => {}
					}
				>
					{props.buttonContent ? props.buttonContent : "Read More"}
				</ButtonComponent>
				{/* eslint-disable*/}
				{popup &&
					props.popup &&
					(!props.asLink ||
						props.link === undefined ||
						!props.asPost) && (
						<PopupSwitcher
							data={props.popup.data}
							handleClick={changePopup}
							type={props.popup.type}
						/>
					)}
			</>
		)
	}
	return <>{buttonType}</>
}

export default ButtonSwitcher
